const modalTrigger = '.js-modal-trigger';

$(document).on('click', modalTrigger, function (e) {
    e.preventDefault();
    let modal = $(this).attr('data-target');
    if ($('#' + modal).hasClass('is-open')) {
        $('#' + modal).removeClass('is-open');
    } else {
        $('#' + modal).addClass('is-open');
    }
});
